<template>
  <el-drawer
    title="任务状态"
    :visible="show"
    :destroy-on-close="true"
    size="1000px"
    @open="onOpend"
    @close="close"
  >
    <div class="w-100 h100 p-10 dp-flex flex-d-c">
      <el-form inline>
        <el-form-item>
          <el-select
            v-model="level"
            placeholder="层级"
            @change="onChange"
            clearable
          >
            <el-option
              v-for="item in levelList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="status"
            placeholder="状态"
            @change="onChange"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="logType"
            placeholder="任务类型"
            @change="onChange"
          >
            <el-option
              v-for="item in logTypeList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="h-0 flex-1">
        <el-table
          :data="list"
          height="100%"
          v-loading="loading"
        >
          <el-table-column
            label="名称"
            prop="name"
          >
            <template slot-scope="{ row }">
              <status :type="!row.hasRead ? 'success' : null">{{ row.name }}</status>
            </template>
          </el-table-column>
          <el-table-column
            label="类型"
            prop="logType"
            :formatter="(row, col, value) => (value == 'COPY' ? '复制' : '创建')"
          ></el-table-column>
          <el-table-column
            label="层级"
            prop="level"
            width="100"
            :formatter="(row, col, value) => (levelList.filter(item => item.value === value)[0].label )"
          ></el-table-column>
          <el-table-column
            label="状态"
            prop="status"
            width="100"
          >
            <template slot-scope="{ row, $index }">
              <el-tooltip
                :content="row.failReason"
                :disabled="row.status != 'failed'"
                placement="bottom"
              >
                <div
                  slot="content"
                  style="max-width: 300px"
                >
                  {{ row.failReason }}
                </div>
                <div>
                  <status :type="row.status == 'failed' ? 'error' : 'success'">{{ statusMap[row.status] }}</status>
                  <el-button
                    type="text"
                    size="mini"
                    v-if="row.retry"
                    @click="handleReLoad(row, $index)"
                    :loading="row.loading"
                    >重新发起</el-button
                  >
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="平台"
            prop="platform"
            width="100"
          ></el-table-column>
          <!-- <el-table-column
            label="失败原因"
            prop="failReason"
          ></el-table-column> -->
          <el-table-column
            label="时间"
            prop="createTime"
            width="180"
          ></el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 10px; text-align: right">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="sizeChange"
          :current-page="pageNumber"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { getCopyList, recopy, retry, hasRead } from '@/api/multiPlatform';
import status from '@/components/common/status.vue';
export default {
  components: { status },
  props: {
    show: Boolean,
  },
  data() {
    return {
      list: [],
      loading: false,
      pageNumber: 1,
      pageSize: 50,
      pageTotal: 0,
      level: null,
      status: null,
      logType: 'COPY',
      levelList: [
        {
          label: '系列',
          value: 'campaign',
        },
        {
          label: '广告组',
          value: 'adset',
        },
        {
          label: '广告',
          value: 'ad',
        },
      ],
      statusList: [
        {
          label: '成功',
          value: 'success',
        },
        {
          label: '失败',
          value: 'failed',
        },
        {
          label: '进行中',
          value: 'copying',
        },
      ],
      statusMap: {
        success: '成功',
        failed: '失败',
        copying: '进行中',
      },
      logTypeList: [
        {
          label: '复制',
          value: 'COPY',
        },
        {
          label: '创建',
          value: 'CREATE',
        },
      ],
    };
  },
  computed: {
    ReLoadApi() {
      if (this.logType === 'COPY') {
        return recopy;
      }
      return retry;
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    sizeChange(size) {
      this.pageSize = size;
      this.pageNumber = 1;
      this.list = [];
      this.getList();
    },
    handleCurrentChange(num) {
      this.pageNumber = num;
      this.getList();
    },
    getList() {
      const data = {
        gkPage: {
          index: this.pageNumber,
          size: this.pageSize,
        },
        level: this.level ? this.level : null,
        status: this.status ? this.status : null,
        logType: this.logType ? this.logType : null,
      };
      this.loading = true;
      getCopyList(data)
        .then((res) => {
          this.list = res.data.copyLogList;
          this.pageTotal = res.data.gkPage.total;
          this.setRead();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setRead() {
      if (this.list.length) {
        let data = this.list.map((item) => item.id);
        hasRead(data).then((res) => {});
      }
    },
    onChange() {
      this.pageNumber = 1;
      this.pageSize = 50;
      this.list = [];
      this.getList();
    },
    onOpend() {
      this.pageNumber = 1;
      this.pageSize = 50;
      this.list = [];
      this.level = null;
      this.status = null;
      this.getList();
    },
    handleReLoad(row, index) {
      this.$set(this.list[index], 'loading', true);
      this.ReLoadApi({ id: row.id })
        .then((res) => {
          this.$set(this.list[index], 'retry', false);
          this.$message.success('发起成功');
        })
        .finally(() => {
          this.$set(this.list[index], 'loading', false);
        });
    },
  },
};
</script>
<style></style>
